<template>
  <div
    id="app"
    class="bg-page d-flex flex-column"
    :lang="myLanguage">
    <b-progress
      :value="100"
      height="5px"
      :variant="online ? 'success' : 'danger'"
      :animated="!online"></b-progress>
    <spinner />
    <!-- navbar -->
    <b-navbar
      v-if="!isLRPage && showTopBar"
      ref="navBar"
      toggleable="lg"
      type="dark"
      variant="success"
      class="main-nav"
      style="z-index: 2500">
      <b-navbar-brand to="/">
        <img
          :src="logoSrc"
          width="auto"
          height="51"
          class="d-inline-block align-top"
          alt="FRE" />
      </b-navbar-brand>
      <b-navbar-toggle
        target="nav_collapse"
        class="mr-2" />
      <b-collapse
        id="nav_collapse"
        is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            v-for="menuItem in menuItemsToShow"
            :key="menuItem.key"
            :to="menuItem.route">
            {{ $t(menuItem.title) }}
          </b-nav-item>
          <b-nav-item
            v-if="$hasRole('monitor') && !hasAdminLicense"
            key="menu-active-licence"
            to="/account/subscriptions"
            class="nav-link-custom">
            {{ $t('LNG_ACTIVATE_LICENCE') }}
          </b-nav-item>
          <b-nav-item-dropdown
            v-if="$can('accounting', 'read')"
            key="menu-accounting"
            :text="$t('LNG_ACCOUNTING')"
            toggle-class="nav-link-custom"
            right>
            <b-dropdown-item
              v-for="accountingMenuItem in accountingMenuItems"
              :key="accountingMenuItem.key"
              :to="accountingMenuItem.route">
              {{ $t(accountingMenuItem.title) }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            v-if="$can('app', 'all')"
            key="menu-settings"
            :text="$t('LNG_SETTINGS')"
            toggle-class="nav-link-custom"
            right>
            <b-dropdown-item
              v-for="settingsMenuItem in settingsMenuItems"
              :key="settingsMenuItem.key"
              :to="settingsMenuItem.route">
              {{ $t(settingsMenuItem.title) }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-form v-if="isOnGoingLesson">
            <b-button
              variant="danger"
              to="/lesson/on">
              {{ $t('LNG_ONGOING_LESSON_INFO') }}
              <i class="fas fa-user-graduate fa-xl ml-1"></i>
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav> </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            v-if="$route.name !== 'privacyPolicy'"
            right
            class="profile-dropdown">
            <template #button-content>
              <div class="d-flex flex-row">
                <div class="d-flex align-items-center">
                  {{ me ? me.firstname : '' }}<br />
                  {{ me ? me.name : '' }}
                </div>
                <div>
                  <img
                    src="./assets/user.png"
                    height="39"
                    width="39"
                    class="profile-dropdown-facepic rounded-circle is-shadowed"
                    alt="" />
                </div>
              </div>
            </template>
            <b-dropdown-item
              v-if="$can('wizard', 'read')"
              variant="danger"
              @click="showWizard"
              >{{ $t('LNG_CONFIGURATION_WIZARD') }}</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/account">{{ $t('LNG_SETTINGS') }}</b-dropdown-item>
            <b-dropdown-item
              :href="lManagerReleaseURL"
              target="_blank"
              >{{ $t('LNG_NEW_RELEASE_LINK') }}</b-dropdown-item
            >
            <b-dropdown-item @click="deauthenticate">{{ $t('LNG_LOGOUT') }}</b-dropdown-item>
            <b-dropdown-form>
              <b-form-group>
                <b-form-radio-group
                  id="app-language"
                  ref="appLanguage"
                  key="app-language"
                  v-model="myLanguage"
                  name="app-language"
                  buttons>
                  <b-form-radio
                    v-for="(language, key) in myLanguages"
                    :key="`lang-${key}`"
                    :value="language.value">
                    {{ language.code }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-item
              :href="lManagerReleaseURL"
              target="_blank"
              ><i class="fas fa-code fa-sm mr-2" /><span class="fs-80">{{
                `${$t('LNG_VERSION')} ${version}`
              }}</span></b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-toast
      id="my-toast"
      variant="danger"
      solid
      :visible="!online"
      toaster="b-toaster-top-center"
      no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <span class="text-danger mr-2"><i class="fas fa-signal"></i></span>
          <strong class="mr-auto">{{ $t('LNG_NO_CONNECTION') }}</strong>
        </div>
      </template>
      {{ $t('LNG_NO_CONNECTION_HINT') }}
    </b-toast>
    <b-toast
      id="my-toast"
      variant="info"
      solid
      :visible="isReleases"
      toaster="b-toaster-top-center"
      no-auto-hide>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <span class="text-info mr-2"><i class="fas fa-gem"></i></span>
          <strong class="mr-auto">{{ $t('LNG_NEW_RELEASE') }}</strong>
        </div>
      </template>
      <div>
        <p>
          {{ $t('LNG_NEW_RELEASE_INFO') }}
          <a
            class="ml-1"
            :href="lManagerReleaseURL"
            target="_blank"
            >{{ $t('LNG_NEW_RELEASE_LINK') }}</a
          >
        </p>
      </div>
      <div class="container-fluid">
        <div
          v-for="(note, id) in releases"
          :key="`note-${id}`"
          class="row">
          <div class="col">
            <div class="d-flex flex-row justify-content-between lead">
              <div class="d-flex">{{ `${$t('LNG_VERSION')} ${note.version}` }}</div>
              <div class="d-flex">{{ $moment(note.date, 'YYYY-MM-DD').format('DD.MM.YYYY') }}</div>
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex">{{ note.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </b-toast>
    <wizard-window ref="wizard" />
    <div
      :class="[
        'container-xs p-0',
        isLRPage || is404 ? 'd-flex flex-fill justify-content-center' : '',
        isLRPage ? 'vw-100' : '',
        isLRPage ? 'vh-95' : '',
        is404 ? 'justify-content-center vh-80' : '',
        !showTopBar ? 'vw-100' : '',
      ]">
      <router-view />
    </div>
    <footer v-if="isLRPage"></footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import languages from './locales/languages';

export default {
  name: 'LManager',
  components: {
    spinner: () => import('@/components/Spinner.vue'),
    wizardWindow: () => import('@/components/WizardWindow.vue'),
  },
  data() {
    return {
      polling: null,
      myLanguages: languages,
      myLanguage: '',
      isLRPage: false,
      is404: false,
      isLessonPage: false,
      settingsMenuItems: [
        {
          key: 'menu-settings-subscriptions',
          title: 'LNG_SUBSCRIPTIONS',
          route: '/subscriptions',
        },
        {
          key: 'menu-settings-driving-school',
          title: 'LNG_DRIVING_SCHOOLS',
          route: '/driving-school',
        },
        {
          key: 'menu-settings-infrastructures',
          title: 'LNG_INFRASTRUCTURES',
          route: '/infrastructures',
        },
        {
          key: 'menu-settings-trainingcard',
          title: 'LNG_TRAININGCARDS',
          route: '/trainingcards-settings',
        },
        {
          key: 'menu-settings-orders-list',
          title: 'LNG_ORDERS_LIST',
          route: '/settings/orders',
        },
        {
          key: 'menu-settings-vouchers',
          title: 'LNG_VOUCHERS',
          route: '/settings/vouchers',
        },
      ],
      accountingMenuItems: [
        {
          key: 'menu-open-bills',
          title: 'LNG_OPEN_BILLS',
          route: '/accounting/billing',
        },
        {
          key: 'menu-accounting-ca',
          title: 'LNG_ACCOUNTING_CA_MENU',
          route: '/accounting/ca',
        },
      ],
      pagesWOTopBar: [
        'lesson',
        'lessonStart',
        'lessonOn',
        'lessonEnd',
        'trainingCardChoice',
        'trainingCardView',
      ],
      lManagerReleaseURL: process.env.VUE_APP_LMANAGER_RELEASE_URL,
    };
  },
  computed: {
    ...mapGetters([
      'me',
      'isOnGoingLesson',
      'language',
      'online',
      'version',
      'isReleases',
      'releases',
      'isWizard',
      'isLoggedIn',
      'hasAdminLicense',
    ]),
    menuItems() {
      if (this.isLRPage) return [];
      if (this.$route.name === 'privacyPolicy') return [];
      return [
        {
          key: 'menu-home',
          title: 'LNG_HOME',
          route: '/',
          privilege: this.$can('home', 'read'),
        },
        {
          key: 'menu-dashboard',
          title: 'LNG_DASHBOARD',
          route: '/dashboard',
          privilege: this.$hasOnlyRole('user'),
        },
        {
          key: 'menu-calendar',
          title: 'LNG_CALENDAR',
          route: '/calendar',
          privilege: this.$can('calendar', 'read'),
        },
        {
          key: 'menu-students',
          title: 'LNG_STUDENTS',
          route: '/students',
          privilege: this.$can('students', 'manage'),
        },
        {
          key: 'menu-shop',
          title: 'LNG_SHOP',
          route: '/resources',
          privilege: this.$can('resources', 'read'),
        },
        {
          key: 'menu-resources',
          title: 'LNG_RESOURCES',
          route: '/learning/resources',
          privilege: this.$can('trainingcardsSettings', 'read'),
        },
        {
          key: 'menu-theory-test',
          title: 'LNG_THEORY_TEST',
          route: '/theory-test',
          privilege:
            this.$can('theoryTest', 'read') && !this.$hasRole('monitor') && !this.$hasRole('fre'),
        },
        {
          key: 'menu-my-monitor',
          title: 'LNG_MY_MONITOR',
          route: '/account/mymonitor',
          privilege: this.$hasOnlyRole('user'),
        },
      ];
    },
    menuItemsToShow() {
      return this.menuItems.filter((m) => m.privilege);
    },
    isCalendar() {
      return this.$route.name === 'calendar';
    },
    showTopBar() {
      return !this.pagesWOTopBar.includes(this.$route.name);
    },
    logoSrc() {
      return this.me && this.me.logo && this.me.logo !== ''
        ? this.me.logo
        : require('./assets/logo-fre.svg');
    },
    isWizardShown() {
      return this.isLoggedIn && this.isWizard;
    },
  },
  watch: {
    myLanguage(newValue, oldValue) {
      if (newValue !== oldValue) this.changeLanguage(newValue);
    },
    isWizardShown(newValue) {
      if (newValue) {
        this.$refs.wizard.show();
      }
    },
  },
  created() {
    this.$root.$on('notification::show', this.showNotification);
    window.addEventListener('online', this.testConnection);
    window.addEventListener('offline', this.testConnection);
    // Set App Title
    document.title = `LManager - ${this.version}`;
  },
  mounted() {
    this.isLRPage =
      this.$route.name === 'login' ||
      this.$route.name === 'reset' ||
      this.$route.name === 'signup' ||
      this.$route.name === 'register' ||
      this.$route.name === 'checkout';
    this.is404 = this.$route.name === '404' || this.$route.name === '403';
    this.myLanguage = this.language;
  },
  updated() {
    this.isLRPage =
      this.$route.name === 'login' ||
      this.$route.name === 'reset' ||
      this.$route.name === 'signup' ||
      this.$route.name === 'register' ||
      this.$route.name === 'checkout';
    this.is404 = this.$route.name === '404' || this.$route.name === '403';
    this.myLanguage = this.language;
  },
  beforeDestroy() {
    this.$root.$off('notification::show', this.showNotification);
    window.removeEventListener('online', this.testConnection);
    window.removeEventListener('offline', this.testConnection);
  },
  methods: {
    ...mapActions(['updateua', 'deauthenticate', 'changeLanguage', 'testConnection']),
    showNotification(options) {
      const isLink = !!options.link;
      const h = this.$createElement;
      let multiMsg;
      let multiLink;

      if (Array.isArray(options.message)) {
        const myMsg = [];
        options.message.forEach((message) => {
          myMsg.push(
            h('p', [h('i', { class: ['fas', this.faIcon(options.type), 'mr-2'] }), message]),
          );
        });
        multiMsg = h('span', myMsg);
      }

      if (isLink && Array.isArray(options.link)) {
        // do something intelligent here
        const myLinks = [];
        options.link.forEach((link) => {
          myLinks.push(
            h(
              'b-button',
              { class: 'mt-3', props: { variant: 'secondary', block: true, to: link.route } },
              [h('i', { class: ['fas', link.icon, 'mr-2'] }), link.label],
            ),
          );
        });
        multiLink = h('span', myLinks);
      } else if (isLink) {
        multiLink = h(
          'b-button',
          {
            class: 'mt-3',
            props: {
              variant: 'secondary',
              block: true,
              to: options.link.route,
            },
          },
          [h('i', { class: ['fas', options.link.icon, 'mr-2'] }), options.link.label],
        );
      }

      const msg = h('p', { class: ['text-center', 'font-size-14', 'mb-0'] }, [
        h('span', [
          multiMsg ? '' : h('i', { class: ['fas', this.faIcon(options.type), 'mr-2'] }),
          multiMsg || options.message,
        ]),
        isLink ? multiLink : '',
      ]);

      this.$bvToast.toast(msg, {
        variant: options.type,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        autoHideDelay: options.delay ? options.delay : 5000,
      });
    },
    faIcon(type) {
      switch (type) {
        case 'primary':
          return 'fa-info-circle';
        case 'secondary':
          return 'fa-info-circle';
        case 'success':
          return 'fa-check-circle';
        case 'danger':
          return 'fa-times-circle';
        case 'warning':
          return 'fa-exclamation-circle';
        case 'info':
          return 'fa-info-circle';
        case 'light':
          return 'fa-info-circle';
        case 'dark':
          return 'fa-info-circle';
        default:
          return 'fa-info-circle';
      }
    },
    showWizard() {
      this.$refs.wizard.show();
    },
  },
};
</script>
